<template>
  <v-dialog v-model="dialog" persistent :overlay="true" max-width="500px" height="500px" transition="dialog-transition">
    <v-card>
      <v-card-title>
        {{ verification.title }}
      </v-card-title>
      <v-card-text v-if="!only_verified_name">
        <!--
        <v-checkbox v-if="verification.verified_name_type" v-model="form.is_contractor" label="계약자(계약/납부 현황 조회)"
          color="#F36628"></v-checkbox>
        <v-checkbox v-if="verification.verified_name_type" v-model="form.is_tenant" label="입주자(스마트홈/린라이프 이용)"
          color="#F36628" hide-details></v-checkbox>
          
        <v-card-text class="fts12 text-left pd0 mt-2">
          • 계약자이며 입주자이신 분은 모두 선택하세요.<br />
          • 세입자 또는 가족은 입주자만 선택하세요.
        </v-card-text>
        -->
        <v-autocomplete :items="villageList" item-text="display_name" item-value="id" v-model="form.village_id"
          label="단지" no-data-text="해당하는 단지가 존재하지 않습니다."></v-autocomplete>
        <v-row>
          <v-col>
            <v-text-field v-model="form.dong_no" label="동"></v-text-field>
          </v-col>
          <div style="width:5%;"></div>
          <v-col>
            <v-text-field v-model="form.ho_no" label="호"></v-text-field>
          </v-col>
        </v-row>
        <v-slide-y-transition hide-on-leave>
          <v-row v-if="signupCheckWarn" class="body-1 color-1">
            {{ signupCheckWarn }}
          </v-row>
        </v-slide-y-transition>
        <v-list>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <div class="text-left color-5 fts14">
                  ※ 아파트 외 동,호수 입력방법
                </div>
              </v-list-item-content>
            </template>

            <v-list-item>
              <v-list-item-content>
                <div class="fts13 text-left color-6">
                  지하층일 경우 입력방식 : B+층+호실<br />
                  예 : B3층 101호 -> B3101
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-card-text class="fts14 text-left pd0 mt-2">
          <span class="inlineText">계약정보, 납부현황 확인 및 점검/예약 기능을 사용하시려면
            <span class="inlineColor">계약자인증</span> 후 이용
            가능합니다.</span>
          <span class="inlineText">세대 입주 후 스마트홈 및 린라이프 서비스를 이용하시려면
            <span class="inlineColor">입주자인증</span> 후 관리사무소를 통해
            입주자승인을 받으신 다음 이용 가능합니다.</span>
        </v-card-text>
      </v-card-text>
      <v-card-actions class="justify-center pb20">
        <v-btn class="fts18 ftw7 pwidth20 color-2 bgc-4" min-height="50" @click="close(forced)">
          닫기
        </v-btn>
        <v-btn color="primary" class="fts18 ftw7 pwidth37" min-height="50" :disabled="isDisabledButton('contractor')"
          @click="checkType('contractor')">
          계약자인증
        </v-btn>
        <v-btn color="primary" class="fts18 ftw7 pwidth37" min-height="50" :disabled="isDisabledButton('tenant')"
          @click="checkType('tenant')">
          입주자인증
        </v-btn>
      </v-card-actions>
      <div class="text-center" v-if="loadingBar">
        <v-progress-circular fullscreen indeterminate color="red"
          style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"></v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getVillageList, updateUserData, getUserData } from "@/api/index.js";
import { signupcheck } from "@/api/sign";

import { loadNICEMain } from "@/api/NICE_id.js";

export default {
  name: "ContractVerification",
  props: ["forced"],
  data() {
    return {
      dialog: false,
      loadingBar: false,
      nice_form: {
        enc_data: "",
        returnMsg: "",
      },

      villageList: [],
      form: {
        is_tenant: false,
        is_contractor: false,
        update_type: "HOME",
        village_id: "",
        dong_no: "",
        ho_no: "",
      },
    };
  },
  computed: {
    verification() {
      return this.$store.state.data.verification;
    },
    verification_dialog() {
      return this.verification.dialog;
    },
    only_verified_name() {
      return this.verification.only_verified_name;
    },
    user() {
      return this.$store.getters.getUser;
    },
    homeList() {
      return this.$store.getters.getHomeList;
    },
    signupCheckWarn() {
      return this.$store.state.info.signupcheckwarn;
    },
    is_completed() {
      if (this.only_verified_name) {
        return true;
      }

      if (this.verification.verified_name_type) {
        return (
          this.form.village_id &&
          this.form.dong_no &&
          this.form.ho_no &&
          !this.signupCheckWarn &&
          (this.form.is_contractor || this.form.is_tenant)
        );
      } else {
        return (
          this.form.village_id &&
          this.form.dong_no &&
          this.form.ho_no &&
          !this.signupCheckWarn
        );
      }
    },
    selected_village_info() {
      // 현재 선택한 단지의 info
      if (this.form.village_id) {
        var info = this.villageList.find((x) => x.id == this.form.village_id);
        return info;
      }
      return null;
    },
  },
  watch: {
    verification_dialog(val) {
      if (val && !this.villageList.length) {
        this.getvillageListAPI();
      }
      if (val && this.only_verified_name) {
        this.LoadMain();
      } else {
        this.dialog = val;
      }
    },
  },
  methods: {
    // APIs
    LoadMain() {
      this.loadingBar = true;
      this.form.is_test = this.verification.is_test;

      if (this.verification.is_test) {
        updateUserData(this.user.id, this.form).then((re) => {
          this.loadingBar = false;
          this.$store.dispatch("USER_REFRESH");
          this.form.redirect = "mypage";
          this.$store.commit(
            "SUCCESS",
            "계약자 인증 및 세대등록이 완료되었습니다."
          );
          this.close();
        });
      } else {
        this.form.redirect = this.verification.redirect;
        this.form.only_verified_name = this.verification.only_verified_name;
        loadNICEMain(this.form)
          .then((re) => {
            this.loadingBar = false;
            this.nice_form = re.data;
            this.OpenPage();
            this.close();
          })
          .catch((er) => {
            this.loadingBar = false;
            this.$store.commit("ERROR", "계약자 인증 호출에 실패하였습니다.");
            this.$store.commit("SET_VERIFICATON", {
              dialog: false,
              redirect: "",
              is_test: false,
            });
          });
      }
    },
    getvillageListAPI() {
      // 단지 정보 세팅
      getVillageList("is_available=true").then((result) => {
        this.villageList = result.data;
        this.villageList = this.villageList.sort(function (a, b) {
          if (a.display_name < b.display_name) {
            return -1;
          }
          if (a.display_name > b.display_name) {
            return 1;
          }
          return 0;
        });
      });
    },
    isDisabledButton(type) {
      /**
       * 23.1.19 추가, 계약자인증/입주자인증 버튼 활성화
       */
      var is_completed_form = false; // 단지, 동, 호수를 모두 채웠는지
      var is_living = false; // 입주자인증 : 입주중인 단지인지

      if (this.form.village_id && this.form.dong_no && this.form.ho_no) {
        is_completed_form = true;
      }

      if (type == "tenant") {
        // 단지의 상태가 '입주중' 이어야 활성화
        if (this.selected_village_info?.status_code_code == "LIVING") {
          is_living = true;
        }
        return !(is_completed_form && is_living);
      } else if (type == "contractor") {
        return !is_completed_form;
      }
    },
    checkType(type) {
      /**
       * 23.1.19 추가, checkSignup() 호출 전 계약자/입주자 1개 방식만 선택
       */

      // type에 따라 this.form 상태 변경
      if (type == "contractor") {
        this.form.is_contractor = true;
        this.form.is_tenant = false;
      } else if (type == "tenant") {
        this.form.is_contractor = false;
        this.form.is_tenant = true;
      }

      //이미 입주중인 세대를 다시 인증시도할 경우를 체크
      if (
        this.form.is_tenant &&
        this.form.village_id &&
        this.form.dong_no &&
        this.form.ho_no
      ) {
        //유저의 home_list에서 같은 단지/동/호 로 입주한 세대가 있는지 찾는다.
        let existHome = this.user.home_list.find(
          (item) =>
            item.is_tenant &&
            item.village == this.form.village_id &&
            item.dong_no == this.form.dong_no &&
            item.ho_no == this.form.ho_no
        );

        let home_id = 0;
        var status_text = "";

        if (existHome) {
          status_text =
            "이미 입주인증 신청된 세대입니다. 승인대기중이라면 승인을 기다려주세요.";
          this.$store.commit("INFO_UPDATE", {
            type: "signupcheckwarn",
            text: status_text,
          });
          this.$store.commit("INFO_UPDATE", {
            type: "signupcheckHomeId",
            text: home_id,
          });

          return false;
        }
      }

      this.checkSignup();
    },
    checkSignup() {
      /*
        1. 해당 단지에 존재하는 동, 호 인지 체크.
        2. 해당 세대에 계약자가 존재하는지 체크.
      */
      let is_my_home = false;
      if (this.homeList) {
        this.homeList.some((item) => {
          if (
            item.is_contractor &&
            Number(item.village) == Number(this.form.village_id) &&
            item.dong_no == this.form.dong_no &&
            item.ho_no == this.form.ho_no
          ) {
            is_my_home = true;
          }
        });
      }

      if (is_my_home) {
        // 이미 인증이 이루어진 세대라면 서버를 호출하지 않고 바로 인증 (??확인필요)
        this.$store.commit("INFO_UPDATE", {
          type: "signupcheckwarn",
          text: "",
        });
        this.LoadMain();
      } else {
        if (!this.verification.verified_name_type) {
          this.form.is_contractor = true;
          this.form.is_tenant = false;
        }
        this.signupCheckAPI(this.form);
        // this.$store.dispatch("SIGNUP_CHECK", this.form);
      }
    },
    signupCheckAPI(data) {
      data.is_erp_check = true;
      let status_text = "";
      let home_id = 0;

      if (!(data.village_id && data.dong_no && data.ho_no)) {
        // 정보 입력중...
        status_text = "단지 지정 및 동, 호를 입력하세요.";
        this.$store.commit("INFO_UPDATE", {
          type: "signupcheckwarn",
          text: status_text,
        });
        this.$store.commit("INFO_UPDATE", {
          type: "signupcheckHomeId",
          text: home_id,
        });
      } else {
        signupcheck(data).then((res) => {
          if (res.data.status == 200) {
            status_text = null;
            home_id = res.data.home_id;
            this.LoadMain();
          } else {
            status_text = res.data.status_text;
            this.$store.commit("ERROR", res.data.status_text);
          }
          this.$store.commit("INFO_UPDATE", {
            type: "signupcheckwarn",
            text: status_text,
          });

          this.$store.commit("INFO_UPDATE", {
            type: "signupcheckHomeId",
            text: home_id,
          });
        }).catch((err) => {
          console.log(err)
          this.$store.commit(
            "INFO",
            "분양사무실로 연락하여 예약 바랍니다."
          );
        });
      }
    },

    // Utils
    OpenPage() {
      // window.open(
      //   "",
      //   "_self",
      //   "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      // );

      let form = document.createElement("form");

      var EncodeData = document.createElement("input");
      EncodeData.type = "hidden";
      EncodeData.name = "EncodeData";
      EncodeData.value = this.nice_form.enc_data;

      var m = document.createElement("input");
      m.type = "hidden";
      m.name = "m";
      m.value = "checkplusSerivce";

      form.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      form.target = "_self";
      form.appendChild(EncodeData);
      form.appendChild(m);

      document.body.appendChild(form);
      form.submit();
    },
    close(forced = false) {
      if (forced) {
        this.$router.push({ name: "mypage" });
      }
      this.$store.commit("SET_VERIFICATON", {
        title: "실명인증",
        dialog: false,
        redirect: "",
        is_test: false,
      });
      this.reset();
    },
    reset() {
      this.dialog = false;

      this.nice_form = {
        enc_data: "",
        returnMsg: "",
      };

      this.form = {
        is_contractor: false,
        is_tenant: false,
        update_type: "HOME",
        village_id: "",
        dong_no: "",
        ho_no: "",
      };
    },

    updateUserAndPushToContract() {
      console.log(this.user)
      this.$router.replace({ query: {} });
      getUserData("force_update=true").then((res) => {
        console.log(res.data)
        this.$store.commit("SET_USER", res.data);
        this.$forceUpdate();
        if (res.data.is_verified && res.data.home_list.length > 0) {
          alert("계약자 인증이 완료되었습니다. 계약 메뉴로 이동합니다.");
          this.$router.push({ name: "contract" });
        } else {
          window.location.reload();
        }
      });
    }
  },
  created() {
    if (this.$route.query.is_not_verified) {
      alert("인증한 정보와 일치하는 계약정보가 없습니다. 계약 시 작성하신 정보와 인증 정보가 일치하는지 확인해주세요.(성명, 전화번호, 생년월일)\n문의 : 02-557-6575");
      // query 초기화
      this.$router.replace({ query: {} });
    }

    if (this.$route.query.is_verified == 'Y' && this.$route.query.is_contractor == 'Y') {
      this.updateUserAndPushToContract();

    }
    this.getvillageListAPI();
    this.close();
  },
};
</script>

<style>
.inlineText {
  display: block;
  padding-left: 15px;
  word-break: keep-all;
  position: relative;
  margin-bottom: 10px;
}

.inlineText:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: #666666;
  position: absolute;
  top: 6px;
  left: 3px;
}

.inlineColor {
  display: inline;
  color: #f36628;
  font-weight: 700;
}
</style>
